/**
 * This is a container for general herlpers.
 */

export const isFieldRequired = field => {
  if (!field) {
    return;
  }
  let isRequired = false;
  field.configs.forEach(config => {
    if (config.key === 'isRequired') {
      isRequired = config.value;
      return;
    }
  });
  return isRequired;
};

export const isFieldReadOnly = field => {
  if (!field) {
    return;
  }
  let isReadOnly = false;
  field.configs.forEach(config => {
    if (config.key === 'isReadOnly') {
      isReadOnly = config.value;
      return;
    }
  });
  return isReadOnly;
};

export const isHiddenInRecord = field => {
  let hideFromRecords = false;
  if (!field || !field?.configs) {
    return hideFromRecords;
  }
  field.configs.forEach(config => {
    if (config.key === 'hideFromRecords') {
      hideFromRecords = config.value;
      return;
    }
  });
  return hideFromRecords;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const debounce = (fn, delay) => {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
